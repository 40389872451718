export default {
  convertStorageToByte,
  convertStorageToKbMbGb,
  convertStorageToGbnumber,
  convertStorageToGb,
  convertStorageToKb,
  convertStorageToMb,
  convertStorageToTb

};

// แปลงจาก MB GB KB เป็น Byte
function convertStorageToByte(_storage) {
  let splitStorage = _storage.split(" ");
  let databyte = 0;

  if (splitStorage[1] === "KB") {
    databyte =
      typeof splitStorage[0] === "number"
        ? splitStorage[0] * 1024
        : parseFloat(splitStorage[0]) * 1024;
  } else if (splitStorage[1] === "MB") {
    databyte =
      typeof splitStorage[0] === "number"
        ? splitStorage[0] * 1048576
        : parseFloat(splitStorage[0]) * 1048576;
  } else if (splitStorage[1] === "GB") {
    databyte =
      typeof splitStorage[0] === "number"
        ? splitStorage[0] * 1073741824
        : parseFloat(splitStorage[0]) * 1073741824;
  } else if (splitStorage[1] === "TB") {
    databyte =
      typeof splitStorage[0] === "number"
        ? splitStorage[0] * 1099511627776
        : parseFloat(splitStorage[0]) * 1099511627776;
  } else {
    databyte =
      typeof splitStorage[0] === "number"
        ? splitStorage[0]
        : parseFloat(splitStorage[0]);
  }
  return Math.ceil(databyte);
}

// แปลงจาก Byte เป็น MB GB KB
function convertStorageToKbMbGb(_sizefilebyte) {
  let size;
  if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
    size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
  } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
    size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
  } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
    size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
  } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
    size = (_sizefilebyte / 1024).toFixed(2) + " KB";
  } else if (_sizefilebyte === "-") {
    size = _sizefilebyte;
  } else {
    size = _sizefilebyte + " B";
  }

  return size;
}
// แปลงจาก Byte เป็น  GB  แบบเป็นตัวเลข
function convertStorageToGbnumber(_sizefilebyte) {
  let size = 0;
    size = (_sizefilebyte / 1048576).toFixed(2) ;
  return size;
}

// แปลงจาก Byte เป็น GB 
function convertStorageToGb(_sizefilebyte) {
  let size;
 
    size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
  return size;
}

// แปลงจาก Byte เป็น KB
function convertStorageToKb(_sizefilebyte) {
  let size;
  
 
    size = (_sizefilebyte / 1024).toFixed(2) + " KB";
  return size;
}

// แปลงจาก Byte เป็น MB
function convertStorageToMb(_sizefilebyte) {
  let size;
  
    size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
  return size;
}

// แปลงจาก Byte เป็น TB
function convertStorageToTb(_sizefilebyte) {
  let size;
  
    size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
  return size;
}
