export default {
  decodearraybuffer,
};

function decodearraybuffer(res) {
  let decodearraybuffer = new TextDecoder("utf-8")
  let msgarraybuffer = decodearraybuffer.decode(res)
  let msgjson = JSON.parse(msgarraybuffer)

  return msgjson
}