<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1200px">
      <v-card>
        <v-card-title v-if="$t('default') === 'th'"
          >{{ $t("admintoolbar.mydriveadmin") }}&nbsp;{{ data_employee.user_name_th }}&nbsp;</v-card-title
        >
        <v-card-title v-else>{{ $t("admintoolbar.mydriveadmin") }}&nbsp;{{ data_employee.user_name_en }}&nbsp;</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:800px">
          <v-layout row wrap class="pa-2">
            <v-flex xs12 lg12>
          <v-overlay :value="loadprogress" absolute :color="color.BG" dark>
            <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
            <!-- <span :style="colorProgress">&nbsp; loading</span> -->
          </v-overlay>
          <br />
          <v-list nav>
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">folder</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                    <template v-slot:item="props">
                      <v-breadcrumbs-item style="cursor: pointer;" @click="gotofolder(props.item)">
                        <span :style="headerPage">{{ props.item.file_name }}</span>
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-group>
                <!-- <v-list-item-action> -->
                <v-btn icon fab small text @click="backtopreventfolder()" :color="color.theme">
                  <v-icon>keyboard_backspace</v-icon>
                </v-btn>
                <!-- </v-list-item-action> -->
              </v-list-item-group>
            </v-list-item>
          </v-list>
          <v-layout>
            <!-- <v-btn>ดาวน์โหลดทั้งหมด</v-btn><v-btn>ลบทั้งหมด</v-btn> -->
          </v-layout>
          <div v-if="loaddataprogresstable === true">
            <v-flex lg12 class="ma-2 ml-4 mr-4">
              <div class="text-center">
                <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
              </div>
            </v-flex>
          </div>
          <div v-else>
            <div v-if="resolutionScreen >= 500">
              <v-data-table
                :headers="headers"
                :items="file_data"
                item-key="file_id"
                :single-select="false"
                class="elevation-0"
                item-selected
                :page.sync="page"
                :items-per-page="size"
                :hide-default-footer="true"
                @page-count="pageCount = $event"
                :no-data-text="$t('tablefile.empty')"
              >
                <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                      </template>-->
                <template v-slot:[`header.file_type`]="{ header }">
                  <span :style="headerTable">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.file_name`]="{ header }">
                  <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                </template>
                <template v-slot:[`header.file_owner`]="{ header }">
                  <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                </template>
                <template v-slot:[`header.file_lastdtm`]="{ header }">
                  <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                </template>
                <template v-slot:[`header.file_size`]="{ header }">
                  <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                </template>
                <template v-slot:[`header.file_status`]="{ header }">
                  <span :style="headerTable">{{ $t(header.text) }}</span>
                </template>
                <template v-slot:item="props">
                  <tr style="cursor: pointer" @dblclick="gotodirectory(props.item.file_id, props.item.file_type, props.item)">
                    <td width="7%" class="text-center">
                      <v-badge color="orange" overlap icon="link" v-if="props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P'">
                        <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                      </v-badge>

                      <v-icon v-else large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                    </td>

                    <td width="30%">
                      <!-- <div > -->
                      {{ props.item.file_name }}
                      <!-- </div> -->
                    </td>

                    <td width="14%" v-if="$t('default') === 'th'">
                      <!-- <div > -->
                      {{ props.item.file_owner_th }}
                      <!-- </div> -->
                    </td>
                    <td width="14%" v-else>
                      <!-- <div > -->
                      {{ props.item.file_owner_eng }}
                    </td>
                    <td width="15%">{{ formatdatetime(props.item.file_lastdtm) }}</td>
                    <td width="15%">{{ fn_calculatesize(props.item.file_size) }}</td>
                    <!-- <td width="10%" class="text-center" ></td> -->
                    <td width="15%" class="text-center">
                      <v-btn class="elevation-0" fab small @click="downloadfile(props.item)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-btn class="elevation-0" fab small @click="confirmdelete(props.item)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <v-list two-line class="mb-6 pb-6 elevation-0" v-if="this.count_data_folder !== 0">
                <v-list-item v-for="item in file_data" :key="item.title" style="cursor: pointer;">
                  <v-list-item-action>
                    <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="$t('default') === 'th'" v-text="item.file_name"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.file_size"></v-list-item-subtitle>
                    <!-- <v-list-item-subtitle v-text="formatdatetime(item.process_dtm)"></v-list-item-subtitle> -->
                  </v-list-item-content>
                  <v-list-item-action>
                    <!-- <v-btn class="elevation-0" fab small @click="opendetaillog = true , detaildata = item"><v-icon >mdi-information</v-icon></v-btn> -->
                    <v-btn class="elevation-0" fab small @click="downloadfile(props.item)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-btn class="elevation-0" fab small @click="confirmdelete(props.item)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-list v-else class="mb-6 pb-6 elevation-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </div>
          <v-layout row wrap justify-center>
            <v-flex xs11 lg11 v-if="pageCount > 0">
              <v-pagination
                :total-visible="10"
                v-model="page"
                :length="pageCount || 0"
                :color="color.theme"
                @input="clickpagination()"
              ></v-pagination>
            </v-flex>
          </v-layout>
          <v-card-text> </v-card-text>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="closeadminmydrive()">{{ $t("admin.close") }}</v-btn>
          <!-- <v-btn :color="color.theme" :dark="color.darkTheme" @click="settingfeature()">{{ $t("admin.submit") }}</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogsnackbardowload
      :show="opensnackbar"
      @closedialog="opensnackbar = false"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowload>
      <dialogsnackbermultidowload
        :show="opensnackbarmultidownload"
        @closedialog="opensnackbarmultidownload = false"
        :percent="percent"
        :date="datetime"
        :btsdownload="btsdownload"
      ></dialogsnackbermultidowload>
    <dialogsnackbardowloadfile
      :show="opensnackbardownloadfile"
      @closedialog="opensnackbardownloadfile = false"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowloadfile>
    <previewfile
      :show="openpreviewfile"
      :filedata_1="currentfile"
      :arrayfile="file_data"
      :parentfolder="parentfolder"
      @closepreviewfile="openpreviewfile = false"
      @openpreviewfile="openpreviewfile = true"
    ></previewfile>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import gb_sizeDataConverter from "@/globalFunctions/sizeDataConverter";
const dialogsnackbardowloadfile = () => import("../optional/dialog-snackbardownloadfile");
const dialogsnackbardowload = () => import("../optional/dialog-snackbardownload");
const dialogsnackbermultidowload = () => import("../optional/dialog-snackbarmultidowmload");
const previewfile = () => import("@/components/optional/dialog-preview");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "data_employee", "functionsetting"],
  components: { dialogsnackbardowloadfile, dialogsnackbardowload, dialogsnackbermultidowload, previewfile },
  data: function() {
    return {
      parentfolder: "",
      currentfile: {},
      openpreviewfile: false,
      navigate: [],
      file: {},
      percent: 0,
      datetime: "",
      opensnackbardownloadfile: false,
      opensnackbarmultidownload: false,
      opensnackbar: false,
      newpercen: [],
      namefile: "",
      new_list: [],
      newlist: [],
      dataIndex: "",
      datadowload: {},
      listdatadowload: [],
      listdowload: [],
      listdatadowload: [],
      newlist: [],
      btsdownload: false,
      percentCompleted: 0,
      count_data_folder: 0,
      size: 20,
      page: 1,
      file_data: [],
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          value: "file_name",
          width: "30%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          value: "file_owner",
          width: "14%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "15%",
          align: "left",
          sortable: false,
        },
      ],
      SWmydrive: false,
      SWmyinbox: false,
      SWmyoutbox: false,
      SWshare: false,
      SWtrash: false,
      SWstared: false,
      createprogress: false,
      nametitle: "",
      selectall: false,
      switchfeature: "",
      feature: [],
      selected: [],
      test: 1,
      loadprogress: false,
      loaddataprogresstable: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          if (this.test === 1) {
            console.log(this.data_employee);
            this.fn_load_file_employee();
            this.navigate.push({ file_id: this.data_employee["account_folder_id"], file_name: this.$t("toolbar.mydrive") });
          }
          this.test = 0;
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.selected = [];
        }
      },
    },
    pageCount: {
      get() {
        let l = this.count_data_folder;
        let s = this.size;
        console.log("Math.ceil(l / s);",Math.ceil(l / s));
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
  },

  methods: {
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },

    fn_calculatesize(_sizefilebyte) {
      return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },
    gotofolder(parameter) {
      console.log(parameter);
      //console.log(this.navigate[this.navigate.length-1]);

      for (let i = 0; i < this.navigate.length; i++) {
        if (parameter.file_id === this.navigate[i].file_id) {
          console.log(i);

          this.navigate.splice(i + 1);
          break;
        }
      }
      console.log(this.navigate);
      this.fn_load_file_employee(parameter.file_id);
    },
    gotodirectory(folderid, type, file) {
      if (type === "folder") {
        console.log("m", this.navigate);
        //if(folderid !== this.navigate[this.navigate.length-1].file_id){
        this.navigate.push({ file_id: folderid, file_name: file.file_name });
        // console.log(this.navigate);
        //}

        this.fn_load_file_employee(folderid);
      } else {
        console.log("preview", file);
        this.currentfile = file;
        this.openpreviewfile = true;
      }
    },
    backtopreventfolder() {
      if (this.navigate.length === 1) {
        this.fn_load_file_employee();
      } else {
        this.navigate.pop();
        this.fn_load_file_employee(this.navigate[this.navigate.length - 1].file_id);
        console.log(this.navigate);
      }
    },
    confirmdelete(file) {
      Swal.fire({
        title: this.$t("admin.confirmdelete"),
        text: this.$t("admin.confirmdelete_text1") + " " + file.file_name + " " + this.$t("admin.confirmdelete_text2"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: this.color.theme,
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("admin.submit"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.callremove(file);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // let objIndex = this.staff.findIndex((obj) => obj.staff_id === item.staff_id);
          // console.log(objIndex, this.staff[objIndex]);
          // this.staff[objIndex]["status_admin"] = !item.status_admin;
        }
      });
    },
    clickpagination() {
      this.fn_load_file_employee();
    },
    async fn_load_file_employee(folderid) {
      this.loaddataprogresstable = true;
      this.file_data = [];

      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      let folder_id;
      if (this.navigate.length > 1) {
        console.log("folderid", folderid);
        folder_id = this.navigate[this.navigate.length - 1].file_id;
      } else {
        console.log("folderid....");
        folder_id = this.data_employee["account_folder_id"];
      }
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_admin: this.dataAccountId,
        account_id: this.data_employee["account_id"],
        user_id: this.data_employee["user_id"],
        folder_id: folder_id,
        limit: cal_limit,
        offset: cal_offset,
        status_folder: "",
        status_file: "",
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/admin/api/select_files_and_folder_employee", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.loaddataprogresstable = false;
            this.count_data_folder = response.data.count;
            for (let i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["name"].split(".");
              datafolder["account_id"] = response.data.data[i]["account_id"];
              datafolder["user_id"] = response.data.data[i]["user_id"];
              datafolder["file_size"] = response.data.data[i]["size"];
              datafolder["permission_setting"] = response.data.data[i]["permission_setting"];
              datafolder["permission_department_setting"] = response.data.data[i]["permission_department_setting"];
              datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
              datafolder["status_updating"] = response.data.data[i]["status_updating"];
              datafolder["data_type"] = response.data.data[i]["data_type"];
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["name"];
              datafolder["file_permission"] = {
                admin_data: "True",
                delete: "True",
                download: "True",
                edit: "True",
                upload: "True",
                view_only: "True",
              };
              datafolder["file_department"] = "";
              datafolder["business_id"] = response.data.data[i]["business_id"];
              datafolder["file_lastdtm"] = response.data.data[i]["cre_dtm"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["priority"] = response.data.data[i]["priority"];
              datafolder["file_type"] = response.data.data[i]["data_type"];
              datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1], response.data.data[i]["data_type"]);
              this.file_data.push(datafolder);
            }
            console.log(this.file_data, "his.file_dat");
          }else{
            Toast.fire({
            icon: "error",
            title: response.data.status + ": " + response.data.errorMessage,
          });
          }
        }).catch((error) => {
          console.log(error);
          this.loaddataprogresstable = false;
         
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },
    seticon(parameter, item) {
      // console.log(parameter);
      // let parameter_ = parameter.toLowerCase()
      let dataicon;
      if (item === "file") {
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
          dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video-outline", "purple"];
        }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    } 
        else {
          dataicon = ["mdi-file-outline", "black"];
        }
      } else if (item === "folder") {
        dataicon = ["folder", "#FDD361"];
      }

      console.log(dataicon);
      return dataicon;
    },

    closeadminmydrive() {
      this.selected = [];
      this.navigate = [];
      this.test = 1;
      (this.page = 1), this.$emit("closedialogmydrive");
    },
    fn_calculate_size(_sizefilebyte) {
      console.log("ขนาดไฟล์เป็น GB", (_sizefilebyte / 1073741824).toFixed(2));
      return parseFloat((_sizefilebyte / 1073741824).toFixed(2));
    },
    async fn_loop_downloading(all_file,zip_name,url_link){
      let total=0;
      if(all_file.length === 0){
            this.newlist[this.dataIndex]["value"]=100
                  console.log(url_link);
                   window.location.assign(url_link)
                   this.opensnackbar = false;
          }else{
       for(let i = 0 ; i<all_file.length;i++){
         console.log("all_file.length",(1/all_file.length)*100,total);
             
              total += (1/all_file.length)*100
             let res = await this.downloading_folder(all_file[i]["file_id"],all_file[i]["location_file"],zip_name,all_file[i]["path"]);
              console.log(res);
             if(this.newlist[this.dataIndex]["value"] > 99 ){
                this.newlist[this.dataIndex]["value"] = 99
               }else{
               this.newlist[this.dataIndex]["value"] = Math.round(total);
              }
              this.newpercen = this.newlist[this.dataIndex]["value"];
              this.namefile = this.newlist[this.dataIndex]["name"];
              console.log(this.percentCompleted);
              // if(res.status === "OK" ){
               
                 
              // }else{
              //   //continue;
              // }
                console.log(i,(all_file.length)-1);
               if( i === (all_file.length)-1){
                 this.newlist[this.dataIndex]["value"]=100
                  console.log(url_link);
                   window.location.assign(url_link)
                   this.opensnackbar = false;
        //            this.axios.get(url_link, {})
        //  .then((response) => { 
        //   console.log(response);
        // });
                }
                
            
          }
          }
    },
    downloading_folder(file_id,location_file,zip_name,all_file){
      let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/downloading_file?file_id=" +file_id +
          "&location_file=" +location_file+
          "&zip_name=" +zip_name+
          "&path=" +all_file +
          "&account_id=" +
          this.dataAccountId;


          console.log(url);
          return new Promise((resolve, reject) => {
        this.axios.get(url, {}).then((response) => {
          console.log("testtt",response.data.status);
          // if(response.data.status === "OK"){
           
          setTimeout(() => {
                resolve({
                  status: response.data.status,
                  msg: "",
                });
              }, 1);
                
          // }else{
          //    return new Promise((resolve, reject) => {
          // setTimeout(() => {
          //       resolve({
          //         status: response.data.status,
          //         msg: "",
          //       });
          //     }, 1000);
          //       });
          // }

        });
        });

        
    },
      async downloadfile(currentfile) {
      this.$emit("closecurrentonly")
      console.log("currentfile",currentfile);
      let checkfilesize = this.fn_calculate_size(currentfile.file_size)
      this.btsdownload = true;
      // console.log(currentfile);
      let datadowload = {};

      // console.log(this.listdatadowload);
      // check listdatadowload ว่าซ้ำมั้ย
      // let checkdata = this.listdatadowload.findIndex((v) => v.file_id === currentfile.file_id);
      // if (checkdata != -1) {
      //   Toast.fire({
      //     icon: "error",
      //     title: "พบการดาวน์โหลดซ้ำ กรุณาดาวน์โหลดใหม่อีกครั้ง",
      //   });
      //   // this.snackbardowload = false;
      //   this.opensnackbar = false;
      //   this.listdatadowload = [];
        this.newlist = [];
      // } else {
      this.listdatadowload.push(currentfile);

      // console.log(this.listdatadowload);

      for (let i = 0; i < this.listdatadowload.length; i++) {
        datadowload["fileid"] = this.listdatadowload[i].file_id;
        datadowload["name"] = this.listdatadowload[i].file_name;
        datadowload["value"] = 0;
        // console.log(datadowload);
      }

      this.newlist.push(datadowload);
      // console.log(this.newlist);

      this.percentCompleted = 0;
      if (currentfile.file_type === "folder") {

        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].file_name);
        }
        //this.btsdownload = true;
        this.opensnackbar = true;
        this.new_list = this.newlist;
        let  timeout = 4000

        let all_file=[] ;
        let zip_name="" ;
        let url_link ;
          
        
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/request/download_folder?account_id=" +
          this.dataAccountId +
          "&folder_id=" +
          currentfile.file_id;

        this.axios.get(url, {})
         .then((response) => { 
        //console.log(response.data.status);
        if(response.data.status === "OK"){
          // all_file = response.data.all_file
          // zip_name =  response.data.zip_name
          // url_link = response.data.url
          //console.log("fff",url_link);
          this.fn_loop_downloading(response.data.all_file,response.data.zip_name,response.data.url);
       
        }else{
              this.btsdownload = false;
              this.opensnackbar = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
              });
        }
        })
        .catch((error) => {
              this.btsdownload = false;
              this.opensnackbar = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
              });
              console.log("err", error);
            });
         
        // for (let i = 0; i < this.listdatadowload.length; i++) {
        //   this.dataIndex = this.newlist.findIndex((obj) => obj.fileid === this.listdatadowload[i].file_id);
        // }
        // console.log("folder", this.dataIndex);

        // this.opensnackbar = true;
        // this.new_list = this.newlist;
        // let auth = await gbfGenerate.generateToken();
          // this.axios
          // .get(url, {
          //   headers: { Authorization: auth.code },
          //   onDownloadProgress: (progressEvent) => {
          //     // console.log("progressEvent", progressEvent);
          //     let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          //     if (this.newlist[this.dataIndex]["value"] === 95) {
          //       this.newlist[this.dataIndex]["value"] = 95;
          //     } else {
          //       this.newlist[this.dataIndex]["value"] = progresspercent;
          //     }
          //     // console.log("percenfolder", this.newlist[this.dataIndex]["value"]);
          //     this.newpercen = this.newlist[this.dataIndex]["value"];
          //     this.namefile = this.newlist[this.dataIndex]["name"];
          //   },
          //   withCredentials: false,
          //   responseType: "arraybuffer",
          // })
          // .then(response => {
          //   console.log("reponse folder",response);
          //   // ตรวจสอบว่า  API ทำงานถูกต้อง
          //   if (response.statusText === "OK") {
          //     // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
          //     if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
          //        // Backend ไม่ส่งไฟล์มา
          //       this.opensnackbar = false;
          //       let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
          //       Toast.fire({ icon: "error", title: res_error.errorMessage });
          //       } else {
          //         // Backend ส่งไฟล์มา
          //         this.newlist[this.dataIndex]["value"] = 100;
          //         this.btsdownload = false;
          //         let headers = response.headers;
          //         let blob = new Blob([response.data],{type:headers['content-type']});
          //         let link = document.createElement("a");
          //         link.href = window.URL.createObjectURL(blob);
          //         link.download = currentfile.file_name;
          //         link.click();
          //         setTimeout(() => {
          //           this.opensnackbar = false;
          //         }, 2500)
          //       }
          //   }else{
          //      this.opensnackbar = false;
          //   }
          //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
          //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
          //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
          //   // console.log("ดาวน์โหลดเสร็จ",this.newlist);
          //   // console.log("ดาวน์โหลดเสร็จpercen",this.newpercen);
          // }).catch(error => {
          //   this.btsdownload = false;
          //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
          //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
          //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
          //   Toast.fire({
          //     icon: "error",
          //     title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
          //   });
          //   console.log(error);
          // });
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id +
          "&account_id=" +
          this.dataAccountId


        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].file_name);
        }
        // console.log("file", this.dataIndex);

        if(checkfilesize < 1.00){
          this.opensnackbar = true;
          this.new_list = this.newlist;
          let auth = await gbfGenerate.generateToken();
          console.log("น้อยกว่า 1 GB");
          this.axios
            .get(url, {
              headers: { Authorization: auth.code },
              onDownloadProgress: (progressEvent) => {
                // console.log("progressEvent", progressEvent);
                let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // console.log(this.percentCompleted);

                if (this.newlist[this.dataIndex]["value"] === 95) {
                  this.newlist[this.dataIndex]["value"] = 95;
                } else {
                  this.newlist[this.dataIndex]["value"] = progresspercent;
                }
                // console.log("percenfile", this.newlist[this.dataIndex]["value"]);
                this.newpercen = this.newlist[this.dataIndex]["value"];
                this.namefile = this.newlist[this.dataIndex]["name"];
              },
              withCredentials: false,
              responseType: "arraybuffer",
            })
            .then(response => {
              // ตรวจสอบว่า  API ทำงานถูกต้อง
              if (response.statusText === "OK") {
                console.log("response++++++++++++ OK", response);
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                  // Backend ไม่ส่งไฟล์มา
                  console.log("response < 1000", response);
                  this.opensnackbar = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(
                    response.data
                  );
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                  // Backend ส่งไฟล์มา
                } else {
                  // console.log("response โหลดได้", response);
                  // console.log(parseInt(response.headers["content-length"]));
                  this.opensnackbar = true;
                  this.newlist[this.dataIndex]["value"] = 100;
                  this.btsdownload = false;

                  let headers = response.headers;
                  let blob = new Blob([response.data],{type:headers['content-type']});
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = currentfile.file_name;
                  link.click();
                  // const blob = new Blob([response.data]);
                  // const content = response.headers["content-type"];
                  // saveAs(blob, currentfile.file_name);
                  setTimeout(() => {
                    this.opensnackbar = false;
                  }, 2500);
                }
              }
              // this.opensnackbar = false;
              this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
              this.newlist = []; //เคลียร์ array เป็น key แล้ว
              this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
              // console.log("ดาวน์โหลดเสร็จfile",this.newlist);
              // console.log("ดาวน์โหลดเสร็จpercenfile",this.newpercen);
            })
            .catch((error) => {
              this.btsdownload = false;
              this.opensnackbar = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
              });
              console.log("err", error);
            });
        }else{
          this.btsdownload = true;
          this.opensnackbardownloadfile = true;
          let  timeout = (checkfilesize*4000)
          window.location.assign(url)
          setTimeout(() => {
            this.opensnackbardownloadfile = false;
    
}, timeout)
         
        }
      }
      // }
    },
    callremove(file) {
      if (file["file_type"] === "folder") {
        this.deletefolder(file);
      } else {
        this.deletefile(file);
      }
    },
    // ลบไฟล์
    async deletefile(file) {
      console.log("fileeeeee", file);

      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        file_id: file["file_id"],
        // type:file.file_type ชนิดของไฟล์
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_file", payload, {
        headers: { Authorization: auth.code },
      });
      try {
        console.log(response);
        if (response.data.status === "OK") {
          Toast.fire({
            icon: "success",
            title: this.$t("sharefile.deletesuc"),
          });
          this.fn_load_file_employee();
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.status + ": " + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: ex,
        });
        // }
      }
    },
    // ลบโฟลเดอร์
    async deletefolder(file) {
      console.log("folder", file);

      // if(parameter.file_owner_eng === 'Me' && parameter.file_owner_th === 'ฉัน'){
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        folder_id: file["file_id"],
        // type:file.file_type,
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(process.env.VUE_APP_SERVICE_DELETE_FOLDER + "/api/delete_folder", payload, {
        headers: { Authorization: auth.code },
      });
      try {
        console.log(response);
        if (response.data.status === "OK") {
          Toast.fire({
            icon: "success",
            title: this.$t("sharefile.deletesuc"),
          });
          this.fn_load_file_employee();
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.status + ": " + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: ex,
        });
      }
      // }
    },
  },
};
</script>
<style></style>
